@import '~bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body {
  margin: 0;
  display: block;
  overflow-x: hidden;
  font-family: 'Montserrat', sans-serif, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  font-size: 16px !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[data-reach-skip-nav-link]:focus {
  top: 0px !important;
  left: 45% !important;
  z-index: 3 !important;
}
.infoBanner {
  background-color: #544745;
  color: white;
  padding: 0px;
  line-height: 40px;
  font-size: 18px;
}
p.info-banner-message {
  margin-bottom: 0 !important;
  line-height: 24px;
  padding: 5px;
  a {
    color: #ffffff !important;
    text-decoration: underline;
  }
}
div.popover-body {
  padding: 0.5rem 0.75rem !important;
}
.header-logo {
  width: 200px;
  height: auto;
}
.anniversary-logo {
  width: 420px;
  padding-left: 60px;
}
.modal-header {
  padding: 0.3rem 1rem;
}
.fp-insight-logo {
  width: 250px;
  height: auto;
  margin: 20px 0;
}
a.btn.insight-collections {
  margin: 24px 13px 9px !important;
  float: none;
  font-size: 0.7em !important;
  padding: 7px 7px;
  text-align: center;
  width: 90%;
}
.loading-wrapper {
  min-height: calc(100vh - 520px);
}
.navbar {
  padding: 1rem 2.5rem 0rem !important;
}
a {
  color: #00709b !important;
}
a:hover {
  color: #00709b !important;
  text-decoration: underline !important;
  cursor: pointer !important;
}
a.btn:hover {
  text-decoration: none !important;
}
.alert-label {
  font-weight: 600;
  color: #cc390f;
}
.bg-light {
  background-color: #ffffff !important;
}
.btn-primary {
  white-space: nowrap;
  text-align: center;
  color: #26282e !important;
  background-color: #f3b120 !important;
  border-color: #f3b120 !important;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 600 !important;
}
.btn-signup-secondary {
  pointer-events: none;
  height: 25px;
  background-color: #26282e !important;
  border-radius: 50% !important;
}
.btn-signup-primary {
  pointer-events: none;
  height: 25px;
  background-color: #f3b120 !important;
  border-radius: 50% !important;
}
.btn-primary-delete {
  color: #26282e !important;
  background-color: #cc390f !important;
  border-color: #cc390f !important;
  color: #ffffff !important;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 600 !important;
}
.btn-card {
  color: #007cad !important;
  background-color: #ffffff !important;
  border-color: #cccccc !important;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 500 !important;
  line-height: 1 !important;
}
.btn-card:hover {
  color: #ffffff !important;
  background-color: #007cad !important;
  border-color: #007cad !important;
}
.btn-secondary {
  color: #26282e !important;
  background-color: #ffffff !important;
  border-color: #f3b120 !important;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 600 !important;
}
a.btn-secondary:hover {
  color: #26282e !important;
}
.btn-secondary-dark {
  color: #4b4a4a !important;
  background-color: #ebebeb !important;
  border-color: #ebebeb !important;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 600 !important;
}
.btn-secondary-dark.active {
  color: #26282e !important;
  background-color: #ffffff !important;
  border-color: #ebebeb !important;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 600 !important;
}
.btn-outline-secondary:hover {
  background-color: #cccccc !important;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show > .btn-outline-secondary.dropdown-toggle {
  background-color: #212529 !important;
  border-color: #212529 !important;
  color: #ffffff !important;
}
.btn-signup {
  white-space: nowrap;
  text-align: center;
  color: #ffffff !important;
  background-color: #007cad !important;
  border-color: #007cad !important;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 600 !important;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #fff !important;
  text-decoration: none;
  background-color: #00709b !important;
}
a.dropdown-item.active:hover,
a.dropdown-item:active {
  color: #fff !important;
}
.btn-add-post {
  border-radius: 0 0.25rem 0.25rem 0 !important;
}
.btn-for-you,
.btn-trending,
.btn-following {
  color: #60514e !important;
  background-color: #ffffff !important;
  border-color: #cecece !important;
}
.btn-card:hover,
.btn-for-you:hover,
.btn-trending:hover,
.btn-following:hover,
.btn-secondary:hover,
.btn-primary:hover,
.btn-profile:hover,
.btn-profile-delete:hover,
.btn-primary-delete:hover,
.btn-signup:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 19px 0px !important;
}
.btn-for-you.active {
  color: #ffffff !important;
  background-color: #00709b !important;
  border-color: #00709b !important;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 600 !important;
}
.btn-trending.active {
  color: #26282e !important;
  background-color: #f3b120 !important;
  border-color: #f3b120 !important;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 600 !important;
}
.btn-secondary.active {
  color: #26282e !important;
  background-color: #f3b120 !important;
  border-color: #f3b120 !important;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 600 !important;
}
.btn-following.active {
  color: #ffffff !important;
  background-color: #cc390f !important;
  border-color: #cc390f !important;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 600 !important;
}
.form-label {
  font-weight: 500;
  color: #26282e;
}
.form-control {
  content: '';
  input {
    background-color: #f3b120 !important;
  }
}
.form-control-new {
  background-color: #f0f0f0 !important;
}
.btn-outline-secondary {
  color: #26282e !important;
  border-color: #ced4da !important;
}
.form-control-photo {
  position: relative;
  padding-left: 130px !important;
}
.react-tagsinput-input,
.react-tagsinput-input--focused {
  border-color: #ffffff !important;
  border-style: solid !important;
  outline: white !important;
}
.react-tagsinput-tag a::before {
  content: ' ×' !important;
}
input[type='file' i]::-webkit-file-upload-button {
  display: inline-block;
  position: absolute;
  top: 0px;
  left: 0px;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: #f3b120 !important;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 600;
}
.input-group-text {
  border-radius: 0.25rem 0rem 0rem 0.25rem !important;
  border-right: none !important;
}
.form-control:after {
  margin-right: 50px;
}
.react-tagsinput {
  background-color: #fff;
  border: 1px solid #ccc;
  overflow: hidden;
  padding-left: 5px;
  padding-top: 5px;
}

.react-tagsinput--focused {
  border-color: #f3b120 !important;
}

.react-tagsinput-tag {
  background-color: #e9e9e9 !important;
  border: 1px solid #e9e9e9 !important;
  color: #26282e !important;
  display: inline-block;
  font-family: 'Montserrat', sans-serif !important;
  border-radius: 15px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px 8px !important;
}
a.react-tagsinput-remove {
  color: #26282e !important;
}
h1.page-title {
  font-weight: 600;
  font-size: 18px;
}
h1 {
  font-size: 24px !important;
  font-weight: 900 !important;
}
h2 {
  font-size: 18px;
  font-weight: 600;
}
div.bubble-float {
  position: fixed;
  bottom: 50%;
  right: 20px;
  z-index: 10;
}
a.bubble-add {
  background-color: #f3b120;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
a.bubble-add:hover {
  color: #ffffff !important;
  background-color: #26282e;
}
div .addPost {
  color: #26282e !important;
}
a.bubble-question {
  margin-top: 8px;
  background-color: #ffffff;
  color: #26282e !important;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
a.bubble-question:hover {
  color: #ffffff !important;
  background-color: #26282e;
}
div .addPostQuestion {
  color: #26282e !important;
}
label {
  margin-bottom: 0rem;
}
.form-label {
  font-weight: 600;
}
footer {
  background-color: #f7dd9c;
}
footer a {
  color: #60514e !important;
  text-decoration: underline;
}
footer h2 {
  margin-top: 40px;
  font-size: 18px;
  font-weight: 700;
}
.footer-text {
  font-size: 14px;
}
h1.trending-hd {
  font-size: 3.5rem !important;
}
ul.footer-links {
  list-style: none;
  padding: 0;
  margin-bottom: 0rem;
  text-align: center;
  li {
    display: inline-block;
    margin: 0 10px;
  }
}

footer .disclaimer {
  background-color: #f3b120;
  font-weight: 600;
  font-size: 12px;
  min-height: 60px;
}
.avatar {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: #60514e;
  display: inline-block;
  text-align: center;
  margin-right: 10px;
}
.post-avatar.large .avatar {
  width: 76px;
  height: 76px;
}
.post-avatar.collab .avatar {
  width: 38px;
  height: 38px;
  .avatar-image {
    width: 38px;
    height: 38px;
  }
}

.profile-avatar .avatar {
  width: 76px;
  height: 76px;
  .avatar-image {
    width: 76px;
    height: 76px;
  }
}
.profile-image {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: #60514e;
  display: inline-block;
  text-align: center;
  margin-right: 10px;
}
.offline-checkbox {
  margin: 0 0 5px 5px;
  height: 20px;
  width: 20px;
}
a .avatar:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 19px 0px !important;
  background-color: #26282e;
}
.avatar-image {
  width: 44px;
  height: 44px;
  border-radius: 50%;
}
.individual-post > div:first-child {
  display: grid;
}
.individual-post-first-col {
  text-align: center;
}
.individual-post-avatar {
  width: 70px;
  height: 70px;
  margin-right: 10px;
}
.individual-post-avatar-image {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.individual-post-url {
  display: block;
}
#fp-insight-banner {
  padding: 1em 1.5em;
  margin: 22px 20px 20px;
  border-radius: 10px;
}
.fp-insight-banner .fp-text h3 {
  font-size: 20px;
  font-style: italic;
}
.fp-insight-banner .fp-number {
  font-size: 20px;
  font-weight: 600;
}
.fp-insight-banner .fp-link {
  font-size: 24px;
  font-weight: 600;
}
#fp-insight-banner .fp-links a {
  color: #000 !important;
  text-decoration: none;
}
.fp-insight-banner .fp-links img {
  max-width: 80px;
}
.fp-insight-banner .fp-links a h2 {
  font-size: 16px;
  margin-top: 0.5em;
  font-weight: 700;
}
.fp-insight-banner .fp-number span {
  color: #00709b !important;
}

.post-avatar.large .avatar-image {
  width: 76px;
  height: 76px;
}
.search-wrapper {
  flex: 1 1 auto;
}
.resource-wrapper {
  flex: 1 1 auto;
  textarea {
    width: 100%;
    border: 1px solid #ccc;
  }
  p {
    color: #00709b;
  }
}
.search-input {
  width: 87% !important;
}
label span {
  font-weight: normal;
  font-size: 12px;
}
label span.required {
  color: #a00521;
}
.collection {
  max-width: 300px;
  min-width: 300px;
}
.google-height {
  min-height: 46px;
}
.collection-wrapper {
  margin: 0px;
  padding: 10px;
  border: #d6d5d5 1px solid;
  border-radius: 4px;
  overflow: hidden;
  background-color: #ffffff;
}
.collection-wrapper,
.post-wrapper {
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 21px;
  overflow: hidden;
  background-color: #fff;
}
.post-grid {
  margin: 0;
}
.post-wrapper {
  padding: 10px 10px 10px 10px;
  border-radius: 21px;
  overflow: hidden;
  background-color: #ffffff;
}
.home-container .post-wrapper:hover,
.post-wrapper:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 19px 0px !important;
}
.post-type-wrapper {
  padding: 10px;
  border-radius: 21px;
  overflow: hidden;
  background-color: #ffffff;
  position: relative;
}
.user-wrapper {
  min-height: 25px;
}
.post-wrapper .post-type {
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #60514e;
}
.post-wrapper .post-type-post {
  font-weight: 600 !important;
  background-color: #ffe6d7;
  // border:1px solid #ff8d6b;
  color: #212529;
  padding: 1px 4px;
  font-size: 12px;
  border-radius: 4px;
  display: inline-block;
}
.post-wrapper .post-type-collection {
  font-weight: 600 !important;
  background-color: #dff2f9;
  //border:1px solid #8ac9ed;
  color: #212529;
  padding: 1px 4px;
  font-size: 12px;
  border-radius: 4px;
}
.individual-post {
  display: grid;
  grid-template-columns: 0.65fr 0.1fr 1fr;
  gap: 0.125rem;
  padding: 0.5rem;
}
.individual-post-hr {
  width: 1px;
  height: 90%;
  background-color: rgba(0, 0, 0, 0.1);
}
.individual-post-user-wrapper {
  justify-content: center;
}
.user-list-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.user-list-card {
  width: 40%;
}
.tag {
  background-color: #e9e9e9 !important;
  border: 1px solid #e9e9e9 !important;
  color: #26282e !important;
  display: inline-block;
  font-family: 'Montserrat', sans-serif !important;
  border-radius: 14px !important;
  font-size: 10px !important;
  font-weight: 500 !important;
  margin-bottom: 3px;
  margin-right: 3px;
  padding: 2px 8px !important;
}
.tag-profile,
.tag-modal-wrapper .btn-sm {
  font-size: 14px !important;
  font-weight: 400 !important;
}
.tag-modal-wrapper {
  max-height: 200px;
  overflow: auto;
}
.collection-hr {
  display: none;
}
.post-hr {
  border-top: none;
  padding: 5px 0px 5px 0px;
}
.post-tag {
  background-color: #ffe6d7 !important;
  border: 1px solid #ffe6d7 !important;
}
.collection-tag {
  background-color: #dff2f9 !important;
  border: 1px solid #dff2f9 !important;
}
.collection-tag:hover,
.post-tag:hover {
  text-decoration: underline;
}
h2.post-title,
h1.individual-post-title {
  font-size: 16px !important;
  font-weight: 600 !important;
  font-family: 'Montserrat';
}
.post-user {
  display: block;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Montserrat';
}
.individual-post-user {
  font-size: 16px;
  display: block;
  text-align: left;
  margin-left: 10px;
}
.rbt-input-main:focus {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: #80bdff !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.2rem #007bff40 !important;
}
.post-url-wrapper {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 240px;
}
.post-like {
  color: #007cad;
  margin-top: 10px;
}
.post-url {
  font-size: 12px;
  font-family: 'Montserrat';
}
.MuiAccordionSummary-content {
  font-size: 18px;
  font-weight: 500;
  svg {
    margin-top: 3px;
  }
}
.post-avatar {
  display: inline-block;
  max-width: 50px !important;
}
.individual-post-avatar {
  max-width: none;
}
.individual-post-url {
  font-size: 14px;
}
.post-avatar.large {
  max-width: 76px !important;
}
.post-user {
  line-height: 1.4;
}
.post-badges {
  display: flex;
  z-index: 2;
}
.badge-icon {
  width: 40px;
}
.individual-post-badge-icon {
  width: 60px;
}
.alluser-badge-icon {
  width: 40px;
}
h2.popover-header {
  font-size: 14px;
  font-weight: 400;
}
.post-date {
  font-size: 12px;
  font-weight: 500;
  font-family: 'Montserrat';
  color: #707070;
}
ul.following-list {
  padding: 0;
  margin-bottom: 5px;
  list-style: none;
  div {
    margin-top: 10px;
  }
  li {
    padding: 10px;
    border: 1px solid #d6d5d5;
    margin: 10px 0px;
    border-radius: 4px;
  }
}
.following-desc {
  font-weight: 400;
}
ul.tag-list {
  margin: 10px 0;
  padding: 0;
}
span.collection-item-hd {
  line-height: 30px;
}
.collab-wrapper {
  cursor: pointer !important;
}
ul.collaborators-list {
  list-style: none;
  margin: 0;
  padding: 20px 0;
}
ul.collection-list {
  list-style: none;
  padding: 0;
  margin-bottom: 5px !important;
  li {
    margin: 20px 0;
    .MuiAccordionSummary-root.Mui-expanded {
      background-color: #ffffff;
    }
    .MuiAccordionDetails-root {
      padding: 8px 16px 0px 16px;
    }
    .MuiButtonBase-root {
      align-items: flex-start !important;
    }
    a {
      color: #007cad;
    }
    p {
      font-size: 14px;
      font-weight: 400;
    }
    .MuiAccordionSummary-content.Mui-expanded {
      margin: 20px 0 0 0;
    }
    .post-hd {
      width: 100%;
    }
    .post-wrapper {
      width: 100%;
      padding: 0px;
      position: relative;
    }
    ul.post-links li {
      margin: 0px 0;
    }
  }
}
.collection-description {
  font-size: 14px;
  font-weight: 400;
}
ul.post-links-top {
  list-style: none;
  padding: 0;
  margin-bottom: 5px !important;
  position: absolute;
  right: 10px;
}
ul.post-links {
  list-style: none;
  padding: 0;
  margin-bottom: 5px !important;
  position: absolute;
  top: 10px;
  width: 100%;
  li {
    display: inline-block;
  }
  li:last-of-type {
    border-right: none;
  }
}
.btn-post {
  margin: 0 8px;
  color: #60514e !important;
  font-family: 'Montserrat';
  min-width: 97px;
  background-color: #ffffff !important;
  border: 1px solid #f3b120 !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  &[aria-describedby='popover-basic'] {
    background-color: #ffffff;
  }
}
.btn-post.btn-ellipsis {
  width: 33px;
  height: 33px;
  min-width: inherit;
  border-radius: 50% !important;
  margin: 0 0px !important;
}
.btn.btn-ellipsis:hover {
  background-color: rgba(100, 100, 111, 0.1) !important;
  border: 1px solid transparent !important;
}
.btn-popover {
  margin: 0;
  color: #26282e !important;
  font-family: 'Montserrat';
  font-size: 14px !important;
  font-weight: 500 !important;
  text-align: left !important;
}
.btn-popover:hover {
  background-color: #ebebeb;
}
.btn-profile {
  color: #60514e !important;
  font-family: 'Montserrat';
  font-size: 18px !important;
  font-weight: 500 !important;
}
.btn-social {
  color: #60514e !important;
  font-family: 'Montserrat';
  font-size: 22px !important;
  font-weight: 500 !important;
  margin-right: 10px;
}
.small-type {
  font-size: 12px;
  margin-top: 5px;
  margin-left: 5px;
}
.type-11 {
  font-size: 11px;
}
.btn-label {
  font-family: 'Montserrat';
  font-size: 13px !important;
  font-weight: 800 !important;
}
.btn-label-link {
  color: #212529 !important;
}
.btn-profile:hover {
  border: #d6d5d5 1px solid !important;
}
.btn-profile-delete {
  color: #cc390f !important;
  border: #cc390f 1px solid !important;
  font-family: 'Montserrat';
  font-size: 1rem !important;
  font-weight: 600 !important;
}
.btn-profile-delete:hover {
  border: #cc390f 1px solid !important;
  background-color: #cc390f !important;
  color: #ffffff !important;
}
.btn-post:hover {
  border: #f3b120 1px solid !important;
  background-color: #f3b120 !important;
  color: #000 !important;
}
.hydrated {
  --container-height: none;
  --box-shadow: none;
  --wdith: 100% !important;
  --padding: 20px 20px;
}
.form-section-header {
  font-family: 'Montserrat' !important;
}
.btn-gender {
  border: 1px solid #dee2e6 !important;
}
.dropdown-toggle.btn-gender:after {
  margin-left: 2.255em !important;
}
.container.banner {
  padding: 40px 0;
}
.banner h2 {
  font-size: 24px;
  font-weight: 600;
  font-family: 'Montserrat';
}
.form-wrapper {
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  margin-bottom: 20px;
  padding-bottom: 20px;
  div {
    padding: 5px 20px;
  }
}
.login-form-wrapper {
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  border-radius: 10px;
  margin-bottom: 20px;
}
.callout-wrapper {
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  border-radius: 10px;
  margin-bottom: 20px;
  margin-top: 20px;
  padding: 20px;
}
.facebook-btn {
  background-color: #364c8d !important;
  border-color: #364c8d !important;
  color: #ffffff !important;
}
.btn-login {
  background-color: #60514e !important;
  border-color: #60514e !important;
  color: #ffffff !important;
  min-width: max-content;
  margin-right: 30px;
}
.btn-filter {
  margin-bottom: 10px;
}
.btn-removall-wrapper {
  padding-left: 50%;
}
.google-btn {
  background-color: #ffffff !important;
  border-color: #a8a8a8 !important;
  font-family: 'Montserrat';
  img {
    width: 25px;
    margin-right: 10px;
    height: 25px;
    display: inline-flex;
  }
}
div.divider {
  font-weight: 600;
  margin-bottom: 10px;
}
.banner-wrapper-home {
  background-image: url(./images/about/bg_texture_hero_about.png);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 537px;
  background-color: #f5f5f5;
}
.banner-wrapper-collection {
  background: rgb(241, 250, 252);
  background: linear-gradient(
    180deg,
    rgba(241, 250, 252, 1) 0%,
    rgba(241, 250, 252, 1) 57%,
    rgba(255, 255, 255, 1) 100%
  );
  padding: 20px;
}
.notes-wrapper {
  background-color: #ffffff;
  margin: 20px 0 0 0;
  border-radius: 10px;
  padding: 20px;
}
.profile-list {
  list-style: none;
  margin: 0px;
  padding: 0px;
  li {
    font-size: 18px;
    font-weight: 500;
    padding: 5px 0;
    span {
      font-size: 12px;
      font-weight: 400;
    }
  }
}
.profile-details span {
  font-weight: 600;
  font-size: 18px;
}
.profile-hd {
  display: block;
  font-weight: 600;
  font-size: 18px;
}
.btn-link {
  font-weight: 500 !important;
  color: #26282e !important;
  text-decoration: none;
  font-size: 18px !important;
}
.card-header {
  padding: 5px !important;
}
.MuiAccordionSummary-root.Mui-expanded {
  background-color: #ebebeb;
}
.react-tagsinput-input {
  width: 100% !important;
}
.offline iframe,
.offline img {
  display: none !important;
}
.pl-4-5 {
  padding-left: 2rem !important;
}
.about-hd-3 {
  font-size: 1.5rem;
  margin-top: 2rem;
  text-decoration: underline;
}
.about-hd-4 {
  font-size: 1rem;
}
.post-article img {
  display: none;
}
.mobile-show {
  display: none;
  padding-right: 10px;
}
.post-grid span {
  opacity: 1 !important;
}
.btn-search {
  border-radius: 0 0.25rem 0.25rem 0 !important;
}
.notification-wrapper {
  position: relative;
}
.notification-count {
  background-color: #f3b120;
  display: inline-block;
  border-radius: 50%;
  padding: 3px;
  line-height: 14px;
  min-width: 20px;
  text-align: center;
  position: absolute;
  top: 6px;
  left: 28px;
  font-size: 12px;
  font-weight: 600;
}
.verified-shield {
  height: 20px;
  color: #ccc;
  fill: red;
}
.policy-contact {
  font-size: 1.75rem;
  font-weight: 500;
  line-height: 1.2;
}
span.notes {
  font-size: 13px;
  color: #26282e;
}
.notes-btn {
  text-decoration: underline;
}
.password-helper-text {
  font-size: 11px;
  line-height: 12px !important;
}
.toast-wrapper {
  box-sizing: border-box;
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  padding: 18px;
  z-index: 1000;
  border-radius: 0.25rem;
  color: #fff;
  font-weight: 500;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 19px 0px !important;
}
.search-container,
.home-container {
  min-height: calc(100vh - 520px);
}
h1.about_hd {
  font-weight: 900 !important;
  font-size: 3.5rem !important;
}
.btn-language {
  min-width: 155px;
  border: solid 1px #dee2e6 !important;
}
.sideNav-wrapper {
  position: sticky;
  top: 0px;
}
.headroom {
  z-index: 3 !important;
  background-color: #ffffff;
}
.sideNav {
  display: block;
  padding: 20px 10px;
  border-top: solid 1px #dee2e6;
}
a.sideNav {
  color: #212529 !important;
}
a.sideNav .icon-circle {
  color: #ff8d6b;
}
a.sideNav:hover .icon-circle {
  color: #ffffff;
}
a.sideNav:hover,
a.sideNav.active {
  background-color: #ff8d6b;
  color: #212529 !important;
  text-decoration: none !important;
  font-weight: 600;
}
a.return {
  color: #212529 !important;
  font-weight: 500;
}
a.return .icon-circle {
  color: #ff8d6b;
}
a.return:hover .icon-circle {
  color: #ff4713;
}
a.return:hover,
a.return.active {
  color: #212529 !important;
  text-decoration: none !important;
  font-weight: 600;
}
.long-title {
  margin-left: 10px;
}
.community-content-mobile {
  padding-left: 3rem !important;
}
.mobile-margin {
  margin-top: 0px;
}
.line_hero-banner {
  position: absolute;
  width: 180px;
  top: 75px;
  left: 10px;
  z-index: 0;
}
.line_hero_right-banner {
  right: 22%;
  height: 740px;
  z-index: 0;
  top: 250px;
  position: absolute;
}
.mobile-center {
  text-align: left;
}
.notification-date {
  font-size: 11px;
}
.notification-bold {
  font-weight: 600;
}
.notification-name {
  font-weight: 400;
}
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 320px !important;
  font-family: 'Montserrat', sans-serif, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.775rem !important;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.red-text {
  color: darkred;
  font-weight: 600;
  font-size: 16px;
}
.notifications-btn {
  float: right;
  margin-bottom: 5px;
  font-size: 0.775rem !important;
}
.clear-fix {
  clear: both;
}
.home-container {
  background: #f5f5f5;
}
.post-why {
  font-style: italic;
  font-size: 14px;
}
.bt-group {
  position: relative;
  margin-left: 30px;
}
.btn-group-toggle {
  min-width: 150px;
  text-align: center !important;
  padding-right: 50px !important;
  padding-left: 50px !important;
  margin-left: -30px;
  position: relative;
  z-index: 1;
}
.btn-group-toggle.active {
  position: relative;
  z-index: 2;
}
.btn-group-toggle:hover {
  z-index: 3;
}
.post-wrapper {
  p {
    font-size: 14px;
  }
}
.post-wrapper-collection {
  // background-color: #8ac9ed !important;
  p {
    // color:#ffffff;
    font-size: 14px;
  }
  hr {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  h2.post-title {
    color: #ffffff;
  }
}
.badge {
  padding: 0.35em 0.6em !important;
}
.badge-primary {
  color: #26282e !important;
  background-color: #f3b120 !important;
}
.card-actions {
  background-color: #f5f5f5;
  border-radius: 0 0 14px 14px;
  position: relative;
  min-height: 50px;
  margin-top: 25px;
}
.individual-post-card-actions {
  margin-top: 35px;
}
.individual-post-view-collection {
  align-self: flex-end;
}
.likes-wrapper {
  position: absolute;
  width: 45px;
  height: 45px;
  color: #ffffff;
  background-color: #1d709b;
  border-radius: 50%;
  border: solid 5px #ffffff;
  font-size: 16px;
  top: 0px;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
}
.likes-label {
  position: absolute;
  width: 30px;
  font-size: 11px;
  top: 30px;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.likes-wrapper-collections {
  position: absolute;
  width: 45px;
  height: 45px;
  color: #ffffff;
  background-color: #1d709b;
  border-radius: 50%;
  border: solid 5px #ffffff;
  font-size: 16px;
  top: -25px;
  left: 40.7%;
}
.likes-label-collections {
  position: absolute;
  width: 30px;
  font-size: 11px;
  top: 25px;
  left: 44%;
}
.kslogo {
  margin: 0 15px 0px 50px !important;
}
main {
  flex: 1 0 auto;
  min-height: calc(100vh - 535px);
}
.popover-body {
  padding: 0.5rem 0 0.5rem 0.75rem !important;
  color: #212529;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 19px 0px !important;
}
.notifications-wrapper {
  padding: 0;
  padding-right: 10px;
  margin-right: -10px;
  max-height: 50vh;
  min-height: 168px;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}
.ios-message-wrapper {
  position: relative;
}
.ios-safari-message {
  position: absolute;
  bottom: 32px;
  z-index: 2;
  padding: 20px 15px;
  background-color: #ffffff;
  margin: 0 10px;
  border-radius: 8px;
}
.ios-share-icon {
  height: 18px !important;
}
.ios-safari-message:after {
  position: absolute;
  z-index: -1;
  content: '';
  right: calc(50% - 10px);
  bottom: -8px;
  border-style: solid;
  border-width: 0px 10px 10px 10px;
  border-color: transparent transparent #ffffff transparent;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transition-duration: 0.3s;
  transition-property: transform;
}
.ios-text {
  line-height: 18px;
  margin-left: 10px;
}
.hs-responsive-embed-youtube {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  padding-top: 25px;
}
.hs-responsive-embed-youtube iframe {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100% !important;
  height: 100% !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 19px 0px !important;
}
.hd-quote {
  display: none;
}
.btn-hero {
  padding: 1rem 2rem;
  font-size: 1.4rem;
  min-width: 160px;
}
.search-container {
  min-height: 700px;
}
.search-container.pl-5 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}
.search-filter {
  width: 280px !important;
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
  margin-bottom: 20px;
}
.search-filter-wrapper {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 21px;
  min-height: 400px !important;
  .btn-group-vertical {
    width: 100%;

    .btn {
      margin: 1px 0px !important;
    }
    .btn-secondary {
      background-color: #f5f5f5 !important;
      border-radius: 8px !important;
      border-color: #f5f5f5 !important;
      text-align: start !important;
      margin: left 21.33px !important;
      padding-left: 42px;
    }
    .btn-secondary:hover,
    .btn-primary:hover {
      background-color: #ffffff !important;
      border-color: #e8e8e8 !important;
      box-shadow: none !important;
    }
    .btn-primary {
      border-radius: 8px !important;
      background-color: #ffffff !important;
      border-color: #e8e8e8 !important;
      text-align: start !important;
      .icon-check-circle {
        color: #ecaa1b !important;
      }
    }
  }
  .form-control {
    display: inline-table !important;
    input {
      background-color: #ffffff !important;
      #shadow-root div {
        font-size: 12px !important;
      }
    }
  }
  .rbt-token {
    background-color: #e6e6e6;
    border-radius: 20px;
    padding: 2px 10px 2px 10px;
    font-size: 12px;
    margin-bottom: 2px;
    margin-right: 2px;
    display: inline-block;
  }
  .rbt-close {
    margin-left: 5px;
    font-size: 18px;
  }
  .rbt-menu {
    width: auto !important;
    max-width: 340px !important;
  }
}
h3.filter-hd {
  font-size: 22px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 5px;
  line-height: 22px;
}
span.sm-text {
  font-size: 11px;
}
.FPi-icon {
  height: 32px;
}
.sh-callout {
  background-color: #00709b;
  color: #ffffff;
  border-radius: 10px;
}
a.sh-callout-link {
  color: #d0f2ff !important;
  text-decoration: underline;
}
a.sh-callout-link:hover {
  color: #ffffff !important;
  text-decoration: underline;
}
.log-in-user-banner {
  background-color: #00709b;
  color: #ffffff;
  text-align: center;
  margin-bottom: 10px;
  line-height: 24px;
  padding: 5px;
}
// add a 2px white boreder to the bottom of .log-in-user-banner a:link
.log-in-user-banner a:link {
  cursor: pointer !important;
  color: #ffffff !important;
  border-bottom: 2px solid #ffffff;
}
.log-in-user-banner a:visited {
  cursor: pointer !important;
  color: #ffffff !important;
  border-bottom: 2px solid #ffffff;
}
.profile-badge {
  width: 120px;
  cursor: pointer !important;
}
.badge-col {
  position: relative;
}
.badge-row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.btn-badge {
  text-transform: capitalize;
}
.member {
  display: inline-block;
}
.explorer {
  display: inline-block;
  margin-left: -60px;
}
.spark {
  display: inline-block;
  margin-left: -60px;
}
.trailblazer {
  display: inline-block;
  margin-left: -60px;
}
.post-badges .explorer {
  display: inline-block;
  margin-left: -20px;
}
.post-badges .spark {
  display: inline-block;
  margin-left: -20px;
}
.post-badges .trailblazer {
  display: inline-block;
  margin-left: -20px;
}
.alluser-explorer,
.alluser-trailblazer,
.alluser-spark {
  display: inline-block;
  margin-left: -20px;
}
.nav-fill .nav-item.active {
  font-weight: 600;
}
.crop-wrapper {
  max-height: 384px;
  max-width: 384px;
  margin: 0 20px 20px 20px;
}
.crop-wrapper div img {
  width: auto;
  max-height: 384px;
  object-fit: cover;
}
.modal-dialog {
  .modal-title.h4 {
    display: inline;
    min-width: 80%;
  }
  .btn-removall-wrapper {
    padding-left: 10%;
  }
}
@media (max-width: 991px) {
  .border-left.community-content-mobile {
    border-left: none !important;
  }
  .line_hero_right-banner {
    display: none;
  }
  .line_hero-banner {
    display: none;
  }
  .community-content-mobile {
    padding-left: 0rem !important;
    margin-top: 40px;
  }
  .mobile-margin {
    margin-top: 13px;
  }
  .individual-post {
    display: inline;
  }
  .individual-post-hr {
    width: 100%;
    height: auto;
    background-color: rgba(0, 0, 0, 0.1);
  }
  .individual-post-view-collection {
    margin-bottom: 0.5rem;
  }
  .individual-post-first-col {
    text-align: left;
  }
  .individual-post-user-wrapper {
    justify-content: flex-start;
  }
}
@media (max-width: 600px) {
  #fp-insight-banner {
    padding: 1em 1em;
    margin: 20px;
  }
  .fp-insight-banner .fp-text h3 {
    font-size: 1.2em;
    font-style: italic;
  }
  .fp-insight-banner .fp-number {
    font-size: 1.2em;
    font-weight: 600;
  }
  .fp-insight-banner .fp-link {
    font-size: 1em;
    font-weight: 600;
  }
  .anniversary-logo {
    width: 420px;
    padding-left: 11%;
    max-width: 100%;
    margin: 0px auto;
    position: relative;
  }
  .user-list-card {
    width: 100%;
  }
  .mobile-center {
    text-align: center;
  }
  .banner-wrapper {
    margin: 0;
  }
  .kslogo,
  .USAIDLogo {
    max-height: 100px;
  }
  .mobile-container {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .border-left.community-content-mobile {
    border-left: none !important;
  }
  .pl-5.community-content-mobile {
    padding-left: 0px !important;
  }
  .community-content-mobile {
    margin-top: 40px;
  }
  .sideNav {
    display: block;
    padding: 10px;
    border-top: solid 1px #dee2e6;
  }
  .mobile-show {
    display: inline-block;
  }
  .mobile.border-right {
    border-right: none;
  }
  .mobile.border-left {
    border-left: none;
  }
  .container.banner {
    padding: 0 15px;
  }
  .btn-following,
  .btn-trending,
  .btn-for-you {
    font-size: 13px !important;
  }
  .post-grid {
    width: 100% !important;
  }
  .home-container {
    padding-right: 0px !important;
    padding-left: 0px !important;
    background: #e5e5e5;
  }
  .home-container .mt-4 {
    margin-top: 0rem !important;
  }
  .navbar {
    padding: 0.5rem 1rem !important;
  }
  .navbar-collapse.show {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .navbar-collapse {
    .mobile-avatar,
    .mobile-avatar.ml-3 {
      margin: 10px 0px !important;
      text-align: right;
      display: inline-block;
    }
    .notification-wrapper {
      display: inline-block;
    }
    .notification-wrapper.ml-3 {
      margin-left: 0 !important;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  appearance: textfield;
  -moz-appearance: textfield;
}

.fp-insight-banner .fp-text h3 {
  font-size: 20px;
  font-style: italic;
}
.fp-insight-banner .fp-number {
  font-size: 20px;
  font-weight: 600;
}
.fp-insight-banner .fp-link {
  font-size: 24px;
  font-weight: 600;
}
#fp-insight-banner .fp-links a {
  color: #000 !important;
  text-decoration: none;
}
.fp-insight-banner .fp-links img {
  max-width: 80px;
}
.fp-insight-banner .fp-links a h2 {
  font-size: 16px;
  margin-top: 0.5em;
  font-weight: 700;
}
.fp-insight-banner .fp-number span {
  color: #00709b !important;
}
.post-wrapper .post-type-section {
  font-weight: 600 !important;
  background-color: #f8e7c2;
  //border:1px solid #8ac9ed;
  color: #212529;
  padding: 1px 4px;
  font-size: 12px;
  border-radius: 4px;
}
.post-type-section-title {
  font-weight: 600 !important;
  background-color: #f8e7c2;
  //border:1px solid #8ac9ed;
  color: #212529;
  padding: 1px 4px;
  font-size: 12px;
  border-radius: 4px;
}
.btn-signup-secondary {
  background-color: #26282e !important;
  border-radius: 40% !important;
}
.btn-signup-primary {
  background-color: #f3b120 !important;
  border-radius: 40% !important;
}
.step-wrapper {
  padding: 10px 10px 10px 10px;
  border-radius: 21px;
  color: #ffffff;
  overflow: hidden;
  text-align: center;
  background-color: #00709b;
}
.step-5-wrapper {
  padding: 10px 10px 10px 10px;
  border-radius: 21px;
  background-color: #ffffff;
}
.login-form-wrapper {
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  border-radius: 10px;
  margin-bottom: 20px;
  div {
    padding: 5px 5px;
  }
}
.callout-wrapper {
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  border-radius: 10px;
  margin-bottom: 20px;
  margin-top: 20px;
  padding: 20px;
}
.login-center {
  display: flex;
  justify-content: center;
}

.text-italic {
  font-size: 20px;
  font-style: italic;
}

@media (max-width: 768px) {
  .d-flex.mobile-margin {
    flex-direction: column;
    align-items: flex-start;
    min-width: 500px;
  }
}
